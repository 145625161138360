import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './Filters.module.css';
import { useFormik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-gb';
import { dateFormat } from '../../../Constants/Date.constant';
import Button from '@mui/material/Button';
import { InfoMark } from '../InfoMark/InfoMark';
import { ExcludingLines } from './ExcludingLines/ExcludingLines';
import React from 'react';
import { AvlCheckbox } from '../Inputs/Checkbox/AvlCheckbox';

export const fieldNames = {
    text: 'text',
    excludeLines: 'excludeLines',
    date: 'date',
    startDate: 'startDate',
    endDate: 'endDate',
    checkbox: 'checkbox',
};

const defaultFieldOptions = {
    [fieldNames.text]: {
        label: 'Search Text',
        placeholder: 'Text',
    },
    [fieldNames.excludeLines]: {
        label: 'Exclude lines',
        placeholder: 'Text',
    },
    [fieldNames.date]: {
        label: 'Date',
        placeholder: 'Date',
        format: dateFormat,
    },
    [fieldNames.startDate]: {
        label: 'From',
        placeholder: 'From',
        format: dateFormat,
    },
    [fieldNames.endDate]: {
        label: 'To',
        placeholder: 'To',
        format: dateFormat,
    },
    [fieldNames.checkbox]: {
        label: 'Is enabled',
    },
};

const defaultInitialValues = {
    [fieldNames.text]: '',
    [fieldNames.excludeLines]: [],
    [fieldNames.date]: null,
    [fieldNames.startDate]: null,
    [fieldNames.endDate]: null,
    [fieldNames.checkbox]: false,
};

const lowerFieldSxProp = {
    '& .MuiInputBase-root': {
        height: '36px',
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(12px, 7px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -12px) scale(0.75)',
    },
    '& .MuiInputBase-input': {
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    '& .MuiCheckbox-root': {
        paddingTop: '6px',
        paddingBottom: '6px',
    },
};

export function Filters(
    {
        onFiltersChange,
        isDate,
        isDateRange,
        isText,
        isExcludeLines,
        isCheckbox,
        initialValues,
        initialFieldOptions,
    },
) {
    const formik = useFormik({
        initialValues: {
            ...defaultInitialValues,
            ...initialValues,
        },
        onSubmit: onFiltersChange,
        onReset: onFiltersChange,
    });

    const fieldOptions = { ...defaultFieldOptions, ...initialFieldOptions };

    const handleKeyUp = (e) => {
        const code = e.keyCode || e.which;
        const enterId = 13;

        if (code === enterId) {
            void formik.submitForm();
        }
    };

    const datePicker = (fieldName) => {
        const onChange = (value) => formik.setFieldValue(fieldNames[fieldName], value, true);

        return (
            <DatePicker
                className={`${styles.field} ${styles.datePicker}`}
                name={fieldNames[fieldName]}
                label={fieldOptions[fieldName].label}
                format={fieldOptions[fieldName].format}
                value={formik.values[fieldName]}
                onChange={onChange}
                slotProps={{
                    textField: {
                        onKeyUp: handleKeyUp,
                    },
                }}
                sx={lowerFieldSxProp}
            />
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                {isText
                    && (
                        <TextField
                            className={styles.field}
                            name={fieldNames.text}
                            label={fieldOptions.text.label}
                            placeholder={fieldOptions.text.placeholder}
                            onBlur={formik.handleBlur}
                            value={formik.values.text}
                            onChange={formik.handleChange}
                            onKeyUp={handleKeyUp}
                            sx={lowerFieldSxProp}
                        />
                    )}
                {isExcludeLines
                    && (
                        <ExcludingLines
                            initialValues={formik.values[fieldNames.excludeLines]}
                            label={fieldOptions.excludeLines.label}
                            onChange={(excludedLines) => formik.setFieldValue(fieldNames.excludeLines, excludedLines)}
                        />
                    )}
                <div className={styles.datePickersContainer}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                        {isDate && datePicker(fieldNames.date)}
                        {isDateRange
                            && (
                                <div className={styles.rangePicker}>
                                    {datePicker(fieldNames.startDate)}
                                    {datePicker(fieldNames.endDate)}
                                </div>
                            )}
                    </LocalizationProvider>
                </div>
                {isCheckbox
                    && (
                        <AvlCheckbox
                            label={fieldOptions.checkbox.label}
                            name={fieldNames.checkbox}
                            value={formik.values[fieldNames.checkbox]}
                            onChange={formik.handleChange}
                            sx={lowerFieldSxProp}
                        />
                    )}
                <div className={styles.filterControls}>
                    <Button variant="outlined" onClick={formik.handleReset}>Clear</Button>
                    <Button
                        className={styles.applyButton}
                        type="submit"
                        variant="contained"
                        onClick={() => formik.submitForm()}
                    >
                        Apply
                        {isDateRange
                            && (
                                <InfoMark classNames={[styles.infoMark]}>
                                    Please note: The selected date range will be considered inclusive. This
                                    means that both the start and end dates are included in the period
                                </InfoMark>
                            )}
                    </Button>
                </div>
            </div>
        </div>
    );
}

Filters.propTypes = {
    onFiltersChange: PropTypes.func.isRequired,
    isDate: PropTypes.bool,
    isDateRange: PropTypes.bool,
    isText: PropTypes.bool,
    isExcludeLines: PropTypes.bool,
    initialValues: PropTypes.object,
};
