import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { SvgIcon } from '@mui/material';
import styles from './InfoMark.module.css';

export function InfoMark({ children, classNames }) {
    return (
        <Tooltip title={children}>
            <SvgIcon>
                <svg
                    className={`${styles.icon} ${classNames.join(', ')}`}
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M11,17 L9,17 L9,15 L11,15 L11,17 L11,17 Z M13.1,9.3 L12.2,10.2 C11.4,10.9 11,11.5 11,13 L9,13 L9,12.5 C9,11.4 9.4,10.4 10.2,9.7 L11.4,8.4 C11.8,8.1 12,7.6 12,7 C12,5.9 11.1,5 10,5 C8.9,5 8,5.9 8,7 L6,7 C6,4.8 7.8,3 10,3 C12.2,3 14,4.8 14,7 C14,7.9 13.6,8.7 13.1,9.3 L13.1,9.3 Z"
                    />
                </svg>
            </SvgIcon>
        </Tooltip>
    );
}
