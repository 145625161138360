import React from 'react';
import { Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { postServiceStatus } from '../../../Services/Search.services';
import * as yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { datetimeFormat } from '../../../Constants/Date.constant';

const FormContainer = styled('form')(() => ({
    overflow: 'auto',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
}));

function PopUpAddServiceComponent({ getServices, setLoading, setOpenAdding, initialValues }) {
    const validationSchema = yup.object()
        .shape({
            name: yup.string(),
            message: yup.string(),
            isDown: yup.boolean(),
            endAt: yup.date(),
            startAt: yup.date(),
        });
    const formikAdd = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: initialValues?.name,
            message: initialValues?.message,
            isDown: initialValues?.is_down,
            startAt: new Date(initialValues?.start_at),
            endAt: new Date(initialValues?.end_at),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            addService(values);
        },
    });

    const addService = (values) => {
        let addRequestData = {
            name: values.name,
            message: values.message,
            is_down: values.isDown,
            end_at: values.endAt,
            start_at: values.startAt,
        };
        postServiceStatus(addRequestData)
            .then(() => {
                setLoading(true);
                setOpenAdding(false);
                getServices();
                formikAdd.resetForm();
            });
    };

    return (
        <FormContainer
            onSubmit={formikAdd.handleSubmit}
            noValidate="novalidate"
        >
            <Grid
                id={'editInvoicing1'}
                container
                spacing={0}
                alignItems={'center'}
                height={'100%'}
                style={{ marginTop: '20px' }}
            >
                <Grid
                    id="editInvoicing2"
                    item
                    xs={12}
                    sm
                    container
                    flexDirection="column"
                    alignItems="center"
                    rowSpacing={3}
                    height="100%"
                >
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Start At"
                                id="startAt"
                                name="startAt"
                                format={datetimeFormat}
                                value={formikAdd.values.startAt}
                                onChange={(newValue) => {
                                    formikAdd.setFieldValue('startAt', newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="End At"
                                id="endAt"
                                name="endAt"
                                format={datetimeFormat}
                                value={formikAdd.values.endAt}
                                onChange={(newValue) => {
                                    formikAdd.setFieldValue('endAt', newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={formikAdd.values.name}
                            onChange={formikAdd.handleChange}
                            error={formikAdd.touched.name && Boolean(formikAdd.errors.name)}
                            helperText={formikAdd.touched.name && formikAdd.errors.name}
                            style={{ minWidth: '230px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="message"
                            name="message"
                            label="Message"
                            value={formikAdd.values.message}
                            onChange={formikAdd.handleChange}
                            error={formikAdd.touched.message && Boolean(formikAdd.errors.message)}
                            helperText={formikAdd.touched.message && formikAdd.errors.message}
                            multiline
                            rows={6}
                            rowsmax={10}
                            style={{ minHeight: '150px', minWidth: '230px' }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            label="Is Down"
                            control={(
                                <Checkbox
                                    id="isDown"
                                    name="isDown"
                                    value={formikAdd.values.isDown}
                                    onChange={formikAdd.handleChange}
                                    checked={formikAdd.values.isDown}
                                    error={formikAdd.touched.isDown && Boolean(formikAdd.errors.isDown)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size="small"
                            variant="outlined"
                            endIcon={
                                <PublishedWithChangesIcon />
                            }
                            // onClick={handleClose}
                            type={'submit'}
                            sx={{
                                m: 3,
                                position: 'relative',
                                minWidth: '230px',

                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
}

PopUpAddServiceComponent.propTypes = {
    getServices: PropTypes.func,
    setLoading: PropTypes.func,
    setOpenAdding: PropTypes.func,
    initialValues: PropTypes.object,
};
export default PopUpAddServiceComponent;
