import axios from '../Helpers/axios';
import { toast } from 'react-toastify';
import { dateToFormattedString } from '../Utils/Utils';

export const searchRecords = async (wordEntered) => {
    try {
        const response = await axios.get('/api/console/search?query=' + wordEntered);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return handleError(error, null);
    }
};

export const searchEmailRecords = (wordEntered) => {
    return axios.get('/api/console/email/' + wordEntered)
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                return error;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                return error.message;
            }
        });
};

export const getUserInfoWithId = (id) => {
    return axios.get('/api/console/users/' + `${id}`)
        .then((response) => {
            if (response.status === 200) {
                // toast.success("User added successfully")
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request,wrong. id ${id}`, { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.warn(`User info does not exist. id ${id}`);
                }
                return (error);
            } else if (error.request) {
                toast.error(`Connection error. id ${id}`);
                return (error);
            } else {
                toast.error(`Unknown Error. id ${id}`);
                return (error);
            }
        });
};

export const getUserInfo = (email) => {
    return axios.get('/api/console/users' + `?email=${email}`)
        .then((response) => {
            if (response.status === 200) {
                // toast.success("User added successfully")
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request ,{toastId:'badRequestwrong. email ${email}`);
                } else if (error.response.status === 404) {
                    toast.warn(`User info does not exist. email ${email}`);
                }
                return (error);
            } else if (error.request) {
                toast.error(`Connection error. email ${email}`);
                return (error);
            } else {
                toast.error(`Unknown Error. email ${email}`);
                return (error);
            }
        });
    //return axios.get('/api/console/users/'+userId+'/enable');
};
export const suspendUser = (userId) => {
    return axios.put('/api/console/users/' + userId + '/suspend');
};
export const enableUser = (userId) => {
    return axios.put('/api/console/users/' + userId + '/enable');
};
export const getUserFolders = (userId, email, paramUrl) => {
    return axios.get('/api/console/users/' + userId + '/folders?' + paramUrl)
        .then((response) => {
            if (response.status === 200) {
                // toast.success("User added successfully")
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request ,{toastId:'badRequestwrong id. id ${userId}`);
                } else if (error.response.status === 404) {
                    toast.error(`User folders does not exist. id ${userId}`);
                }
            }
            return (error);
        });
};

export const initiateFolderGeneration = (downUrl) => {
    return axios.post(downUrl)
        .then((response) => {
            if (response.status === 202) {
                // toast.success("Folder generation started successfully")
                return response.headers['content-location'];
            } else {
                toast.error('Folder generation failed');
                return '';
            }
        });
};

export const constCheckFolder = async (downloadUrl, projectName) => {

    function downloadFile(downloadUrl, projectName) {
        return new Promise((resolve) => {
            return axios.get(downloadUrl, { responseType: 'blob' })
                .then((response) => {
                    if (response.status === 200) {
                        // get the header of the response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', projectName + '.zip'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // const location = response.headers['content-location'];
                        resolve(true);
                    } else if (response.status === 201) {
                        const location = response.headers['content-location'];
                        const link = document.createElement('a');
                        link.href = location;
                        document.body.appendChild(link);
                        link.click();
                        resolve(true);
                    } else if (response.status === 202) {
                        // get the header of the response
                        const location = response.headers['content-location'];
                        setTimeout(() => {
                            resolve(constCheckFolder(location, projectName));
                        }, 5000);
                    } else {
                        //     after 5 seconds check again
                        setTimeout(() => {
                            resolve(constCheckFolder(downloadUrl, projectName));
                        }, 5000);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        toast.error('Resource not found.');
                    } else {
                        toast.error('Unknown Error.');
                    }
                    resolve(false);

                });
        });
    }

    function checkUpload() {
        const promises = [];

        promises.push(downloadFile(downloadUrl, projectName));

        return Promise.all(promises)
            .then((results) => {
                // console.log("All done", results);
                return true;
            })
            .catch(() => {
                // console.log('e___', e)
                // Handle errors here
                return false;
            });
    }

    return checkUpload();
};

// Download folder
export const downloadFolder = (downUrl, projectName) => {
    return axios.get(downUrl, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', +projectName + '.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response;
        });
};

export const getOrganizationInfo = (organisation, cancelToken) => {
    return axios.get('/api/console/organisations/' + organisation, { cancelToken })
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('Something went wrong. Please contact the developer.');
                return null;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request ,{toastId:'badRequestwrong id. id ${organisation}`);
                } else if (error.response.status === 404) {
                    toast.error(`Organisation info does not exist. id ${organisation}`);
                }
            }

            return null;
        });
};

export const downloadInvoice = (url, orgName, date) => {
    return axios.get(url, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', orgName + dateToFormattedString(date) + '.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response;
        });
};
// Get organization services
export const orgInvoicesList = (orgName, urlParam) => {
    //return axios.get('/api/console/organisations/'+params.orgName+'/invoices?startDate='+params.startDate+'&&endDate/'+params.endDate+'&&offset='+params.offset+'&&limit='+params.limit+'&fromDate='+params.fromDate+'&toDate='+params.toDate);
    return axios.get('/api/console/organisations/' + orgName + '/invoices?' + urlParam);
};
// Download missing invoicing reports in zip file
export const downloadInvoicingReportsZip = () => {
    return axios.get('/api/console/missing-invoices', { responseType: 'blob' })
        .then((response) => {
            if (response.status === 200) {
                const contentType = response.headers['content-type'];
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType || 'application/binary' }));
                const link = document.createElement('a');
                const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                return true;
            }

            return false;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    toast.error('Not Authorised');
                }
            }

            return false;
        });
};
// List of organization folder
export const orgFoldersList = (orgName, urlParam) => {
    return axios.get('/api/console/organisations/' + orgName + '/folders?' + urlParam)
        .then((response) => {
            if (response.status === 200) {
                // toast.success("User added successfully")
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request. probably bad request parameters.', { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.warn(`Org name not found. id ${orgName}`);
                }
            }
            return (error);
        });
};
// get list of organization users
export const orgUsersList = (orgName, urlParam) => {
    return axios.get('/api/console/organisations/' + orgName + '/users?' + urlParam)
        .then((response) => {
            if (response.status === 200) {
                // do not give any success messages
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request ,probably wrong url parameters.', { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.error('Organisation not found');
                }
            }
            return (error);
        });
};

// Get the stats data from the organisation. 
export const getOrgStats = async (orgName, urlParam) => {
    try {
        const response = await axios.get(`/api/console/organisations/${orgName}/stats?${urlParam}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return handleError(error, null);
    }
};

export const generateReport = (orgName, urlParam, appName) => {
    let url = `/api/console/organisation/${orgName}/generate-report?${urlParam}`;

    if (appName) {
        url += `&reportType=${appName}`;
    }

    return axios.post(url);
};

export const downloadPdf = (urlForZip, orgName) => {
    return axios.get(urlForZip, { responseType: 'blob' });
};

export const createFolder = () => {
    return axios.post('/api/filed-documents/folder');
};

export const addFileToFolder = async (folderId, file) => {
    // file upload
    const formData = new FormData();

    function uploadFile(file) {
        return new Promise((resolve) => {
            formData.append('file', file);
            return axios.post('/api/filed-documents/document/' + folderId + '/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response);
                    }
                });
        });
    }

    function checkUpload() {
        const promises = [];

        for (let i = 0; i < file.length; i++) {
            promises.push(uploadFile(file[i]));
        }

        return Promise.all(promises)
            .then((results) => {
                return true;
            })
            .catch(() => {
                // Handle errors here
            });
    }

    // return uploadFile(file)
    return checkUpload();

    // formData.append('file', file[0]);

};

export const getFileKeyData = (folderId) => {
    return axios.get('/api/filed-documents/' + folderId + '/key-data');
};

export const createNewPlan = (form_data) => {
    return axios.post('/api/console/organisations/create', form_data)
        .then((response) => {
            if (response.status === 201) {
                toast.success('Organisation added Successfully');
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.warn('You are not allowed to do this action');
                } else if (error.response.status === 404) {
                    toast.warn('Api Endpoint not found');
                } else if (error.response.status === 409) {
                    toast.warn('Organisation already exists.');
                } else {
                    toast.error('Something went wrong. Please contact the developer.');
                }
            }
            return error;
        });
};
// Update plan to an organisation
export const updatePlan = (orgName, form_data) => {
    return axios.put('/api/console/organisations/' + orgName + '/update', form_data)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Organisation edited successfully.');
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.warn('You are not allowed to do this action');
                } else if (error.response.status === 409) {
                    toast.warn('Organisation already exists.');
                } else {
                    toast.error('Something went wrong. Please contact the developer.');
                }
            }
            return error;
        });
};

export const updateOrganisationStatus = (orgName, isDisabled) => {
    return axios.put(`/api/console/organisations/${orgName}/status?is_disabled=${isDisabled}`)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Organisation status changed successfully.');
            }

            return response;
        })
        .catch((error) => {
            if (error.response?.status === 400) {
                toast.error('Bad request', { toastId: 'badRequest' });
            } else if (error.response?.status === 403) {
                toast.warn('You are not allowed to do this action');
            } else {
                toast.error('Something went wrong. Please contact the developer.');
            }

            return error;
        });
};

export const getOrganisationStatus = (orgName, cancelToken) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/console/organisations/${orgName}/status`, { cancelToken })
            .then((response) => resolve(!!response.data['is_disabled']))
            .catch(reject);
    });
};

// Add Invoice to an organisation
export const addInvoicing = (form_data) => {
    return axios.post('/api/console/organisations/create-invoicing', form_data)
        .then((response) => {
            if (response.status === 201) {
                toast.success('Invoice added successfully.');
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.warn('You are not allowed to do this action');
                } else if (error.response.status === 409) {
                    toast.warn('Invoicing already exists');
                } else {
                    toast.error('Something went wrong');
                }
            }
            return error;
        });
};

// Create a new user
export const createNewUser = (formData) => {
    return axios.post('/api/console/users/create', formData)
        .then((response) => {
            if (response.status === 201) {
                toast.success('User added successfully.');
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
            return response;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request: ${formData?.email} user hasn't been created `, { toastId: 'badRequest' });
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

// Change owner of the project
export const changeOwner = (jsonData, folderId) => {
    return axios.post(`/api/console/folders/${folderId}/change_owner`, jsonData)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Owner changed successfully.');
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
            return true;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.warn('Folder not found');

                } else if (error.response.status === 500) {
                    toast.warn('Bad request', { toastId: 'badRequest' });
                } else {
                    toast.error('Something went wrong');
                }
            }
            return false;

        });
};

// Change owner of the project
export const getInvoicingData = (orgName, urlParam) => {
    return axios.get('/api/console/organisations/' + orgName + '/invoicing?' + urlParam)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request, probably invalid Organisation ${orgName}`, { toastId: 'badRequest' });
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const getServiceStatus = () => {
    return axios.get('/api/console/service-status/list')
        .then((response) => {
            if (response.status === 200) {
                return response;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 500) {
                    toast.warn('Server Error', { toastId: 'genericError' });
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const postServiceStatus = (requestData) => {
    return axios.post('/api/console/service-status/create', requestData)
        .then((response) => {
            if (response.status === 201) {
                toast.success('Service Added successfully.');
                return response.data;
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.error('You are not authorised to perform this action');
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const editServiceStatus = (serviceStatusId, editRequestData) => {
    return axios.put('/api/console/service-status/update/' + serviceStatusId, editRequestData)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Service Edited successfully.');
                return response.data;
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.error('You are not authorised to perform this action');
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const deleteServiceStatus = (serviceStatusId) => {
    return axios.delete('/api/console/service-status/delete/' + serviceStatusId)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.error('You are not authorised to perform this action');
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const getFiledDocument = (paramUrl) => {
    return axios.get('/api/console/filed-documents?' + paramUrl)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.error('You are not authorised to perform this action');
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const downloadPdfFile = (documentId, folderId) => {
    //    /api/console/filed-documents/<folder_id>/<document_id>/download-pdf
    return axios.get(`/api/console/filed-documents/${folderId}/${documentId}/download-pdf`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.error('You are not authorised to perform this action');
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const downloadFoldersByFiltering = (folderId, excludedExtensions, includedExtensions) => {
    //    /api/console/filed-documents/<folder_id>/<document_id>/download-pdf
    //x-www-form-urlencoded
    let params = new URLSearchParams();
    excludedExtensions.forEach((element) => {
        params.append('excluded_extensions', element);
    });
    includedExtensions.forEach((element) => {
        params.append('included_extensions', element);
    });

    return axios.post(`/api/console/filed-documents/${folderId}/download-folder`, params)
        .then((response) => {
            if (response.status === 202) {
                return response.headers['content-location'];
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 403) {
                    toast.error('You are not authorised to perform this action');
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

// get file information from folderId /api/console/files/<fid>/list
export const getFileInfo = (folderId, paramUrl) => {
    return axios.get(`/api/console/files/${folderId}/list?` + paramUrl)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error(`Bad request, probably invalid folderId ${folderId}`, { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.error('Bad request, probably the endpoint is not in production', { toastId: 'badRequest' });
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const disableUsersInOrganisation = (organisation, disabled) => {
    return axios.post(`/api/console/organisation-users/${organisation}/disable?` + `disabled=${disabled}`)
        .then((response) => {
            if (response.status === 202) {
                return response.headers['content-location'];
            } else {
                toast.error('It seems like it worked but we do not know why. Please contact the developer.');
            }
        })
        .catch(() => {
        });
};

export const checkUserStatus = (statusUrl) => {
    return axios.get(statusUrl)
        .then((response) => {
            if (response.status === 202) {
                return setTimeout(() => {
                    return checkUserStatus(response.headers['content-location']);
                }, 5000);
            } else if (response.status === 200) {
                return response.data.success;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 500) {
                    toast.error('Celery related error');
                }
            }
            return error;
        });
};

export const updateInvoicing = (invoicingId, requestData) => {
    return axios.put(`/api/console/invoicing/${invoicingId}/edit`, requestData)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Invoicing updated successfully.');
                return true;
            }
        })
        .catch((error) => {
            if (error.response.status === 400) {
                toast.error('Bad parameter');
            } else if (error.response.status === 404) {
                toast.error('Invoicing not found');
            } else if (error.response.status === 403) {
                toast.error('Authorisation level in not enough');
            } else if (error.response.status === 500) {
                toast.error('Server Error');
            } else {
                toast.error('Something went wrong.Please contact the developer.');
            }

            return false;
        });
};

export const checkOrganisationsStatus = (isTrial) => {
    return axios.get(`/api/console/full_organisation_report?isTrial=${isTrial}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.status === 500) {
                    toast.error('Server Error');
                } else {
                    toast.error('Something went wrong. Please contact the developer.');
                }
            }
            return error;
        });
};

export const getAuditDataOfFolder = (folderId, paramUrl) => {
    return axios.get(`/api/console/folder/${folderId}/audit` + paramUrl)
        .then((response) => {
            if (response.status === 200) {
                return response;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 404) {
                    toast.error('Logs not found', { toastId: 'badRequest' });
                } else {
                    toast.warn('Generic Error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const getOutputSpecification = (organisation) => {
    return axios.get(`/api/console/organisation/${organisation}/output_specification`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.error('Output specification not found');
                } else {
                    toast.warn('Generic error', { toastId: 'genericError' });
                }
            }
            return (error);
        });
};

export const updateOutputSpecification = (organisation, requestData) => {
    return axios.put(`/api/console/organisation/${organisation}/output_specification/update`, requestData)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Output specification updated successfully.');
                return true;
            }

            return false;
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 404) {
                    toast.error('Output specification not found');
                } else {
                    toast.warn('Generic error', { toastId: 'genericError' });
                }
            }
            return false;
        });
};

const handleError = (error, defaultValue) => {
    if (error.response) {
        if (error.response.status === 400) {
            toast.error('Bad request', { toastId: 'badRequest' });
        } else if (error.response.status === 401) {
            toast.error('Unauthorised', { toastId: 'unauthorised' });
        } else if (error.response.status === 404) {
            toast.error('Not found');
        } else {
            toast.warn('Generic error', { toastId: 'genericError' });
        }
    }
    return (defaultValue);
};

export const getAvailableReports = ({ folderId }) => {
    return axios.get(`/api/titles/reports/${folderId}/available`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            return handleError(error, null);
        });
};

export const getReport = ({ folderId, fileName, formatList }) => {
    formatList.map((format) => {
        return axios.get(`/api/titles/report/${folderId}/${fileName}.${format}`, { responseType: 'blob' })
            .then((response) => {
                if (response.status === 200) {
                    const contentType = response.headers['content-type'];
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType || 'application/binary' }));
                    const link = document.createElement('a');
                    const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
                    link.href = url;
                    link.setAttribute('download', fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return true;
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        toast.error('Not Authorised');
                    }
                }
                return false;
            });
    });
};

export const getOverallStatistics = (urlParam) => {
    return axios.get('/api/console/organisations/overall-statistics' + urlParam)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            return handleError(error, null);
        });
};

export const getOverallConfigurations = () => {
    return axios.get('/api/console/configuration-status')
        .then((response) => response.data)
        .catch((error) => handleError(error, null));
};

export const refreshCache = () => {
    return axios.get('/api/console/refresh-cache')
        .then((response) => response.status === 200)
        .catch((error) => handleError(error, null));
};

export const getOrganisationUsers = (organisationName, enabled) => {
    return axios.get(`/api/console/organisation/${organisationName}/firebase-users?enabled=${enabled}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.error('Bad request', { toastId: 'badRequest' });
                } else if (error.response.status === 401) {
                    toast.error('Not Authorised');
                } else if (error.response.status === 404) {
                    toast.error('Not found');
                } else {
                    toast.warn('Generic error', { toastId: 'genericError' });
                }
            }
            return ([]);
        });
};

export const getFeatureFlags = (pageIndex, pageSize, q) => {
    return axios.get('/api/console/feature-flags', {
        params: {
            limit: pageSize,
            offset: pageIndex,
            q: q,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return response;
            }
        })
        .catch((error) => {
            return handleError(error, error);
        });
};

export const createFeatureFlag = (requestData) => {
    return axios.post('/api/console/feature-flag', requestData)
        .then((response) => {
            if (response.status === 201) {
                toast.success('Feature flag created successfully.');
                return response;
            }
        })
        .catch((error) => {
            return handleError(error, error);
        });
};

export const updateFeatureFlag = (featureFlagId, requestData) => {
    return axios.put(`/api/console/feature-flag/${featureFlagId}`, requestData)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Feature flag updated successfully.');
                return response;
            }
        })
        .catch((error) => {
            return handleError(error, error);
        });
};

export const deleteFeatureFlag = (featureFlagId) => {
    return axios.delete(`/api/console/feature-flag/${featureFlagId}`)
        .then((response) => {
            if (response.status === 204) {
                toast.success('Feature flag deleted successfully.');
                return response;
            }
        })
        .catch((error) => {
            return handleError(error, error);
        });
};









