import React, { useEffect, useState } from 'react';
import '../Orgs.css';
import { orgFoldersList } from '../../../../Services/Search.services';
import { Filters } from '../../../Shared/Filters/Filters';
import { format } from 'date-fns';
import { dateFormat } from '../../../../Constants/Date.constant';
import { Header } from '../../../Shared/Header/Header';
import { Columns, ProjectsTable } from '../../../Shared/ProjectsTable/ProjectsTable';

export function OrgProjectsTab({ orgName }) {
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [textSearch, setTextSearch] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getRecords(page);
    }, [textSearch, startDate, endDate, orgName, page]);

    const refreshRecords = () => {
        getRecords(page);
    };

    const makeParams = (paginationPage) => {
        let urlParam = 'limit=' + limit;

        if (textSearch) {
            urlParam += '&query=' + textSearch;
        }
        if (startDate) {
            urlParam += '&startDate=' + startDate;
        }
        if (endDate) {
            urlParam += '&endDate=' + endDate;
        }
        if (page) {
            urlParam += '&offset=' + (paginationPage - 1);
        }

        return urlParam;
    };

    const getRecords = (page) => {
        const urlParam = makeParams(page);

        orgFoldersList(orgName, urlParam)
            .then((res) => {
                if ((res && res.stack && res.message)) {
                    setRecords([]);
                    setLimit(10);
                    setPageCount(1);
                } else {
                    const dataResp = res.data;
                    const count = Number(dataResp.count);
                    const limit = Number(dataResp.limit);
                    const pcount = Math.ceil((count / limit));

                    setRecords(dataResp.folders);
                    setLimit(dataResp.limit);
                    setPageCount(pcount);
                }
            })
            .finally(() => setLoading(false))
            .catch(() => {
                setRecords([]);
                setLimit(10);
                setPageCount(1);
            });
    };

    const handlePageChange = (event, value) => {
        setLoading(true);
        setPage(value);
        getRecords(value);
    };

    const getFilterFormValue = (filterValues) => {
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = startDate && format(startDate, dateFormat);
        const formattedEndDate = endDate && format(endDate, dateFormat);

        setLoading(true);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setTextSearch(filterValues.text);
        setPage(1);
    };

    return (
        <>
            <div className="header">
                <Header
                    title="Projects"
                    filters={(
                        <Filters
                            isText
                            isDateRange
                            onFiltersChange={getFilterFormValue}
                        />
                    )}
                />
            </div>
            <ProjectsTable
                page={page}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                isLoading={loading}
                records={records}
                onRefreshRecords={refreshRecords}
                hiddenColumns={[
                    Columns.createdAt,
                    Columns.lastOpenedAt,
                ]}
            />
        </>
    );
}
