import { Alert, Stack, Typography } from '@mui/material';
import MissingOrgFieldsPicker from './MissingOrgFieldsPicker';
import { useEffect, useState } from 'react';
import styles from './MissingOrgFields.module.css';
import { OrgWithIssues } from './OrgWithIssues';

const issueLabels = [
    {
        name: 'Xero ID',
        color: '#7057ff',
        description: 'xero_id_exists',
        keyValue: 'xero_id_exists',
    },
    {
        name: 'Output Specification',
        color: '#008672',
        description: 'output_specification_exists',
        keyValue: 'output_specification_exists',
    },
    {
        name: 'Monthly Report',
        color: '#b60205',
        description: 'monthly_report_exists',
        keyValue: 'monthly_report_exists',
    },
    {
        name: 'Invoicing Day',
        color: '#d93f0b',
        description: 'invoicing_day_exists',
        keyValue: 'invoicing_day_exists',
    },
    {
        name: 'Matter Number Pattern',
        color: '#0e8a16',
        description: 'matter_number_pattern_exists',
        keyValue: 'matter_number_pattern_exists',
    },
    {
        name: 'Matter Number Pattern Not Default',
        color: '#fbca04',
        description: 'matter_number_pattern_not_default',
        keyValue: 'matter_number_pattern_not_default',
    },
    {
        name: 'Matter Number Error Message',
        color: '#fec1c1',
        description: 'matter_number_error_message_exists',
        keyValue: 'matter_number_error_message_exists',
    },
    {
        name: 'Matter Number Error Message Not Default',
        color: '#215cea',
        description: 'matter_number_error_message_not_default',
        keyValue: 'matter_number_error_message_not_default',
    },
    {
        name: 'Project Name Pattern',
        color: '#cfd3d7',
        description: 'project_name_pattern_exists',
        keyValue: 'project_name_pattern_exists',
    },
    {
        name: 'Project Name Error Message',
        color: '#fef2c0',
        description: 'project_name_error_message_exists',
        keyValue: 'project_name_error_message_exists',
    },
    {
        name: 'Project Name Pattern Not Default',
        color: '#eeeeee',
        description: 'project_name_pattern_not_default',
        keyValue: 'project_name_pattern_not_default',
    },
    {
        name: 'Project Name Error Message Not Default',
        color: '#d73a4a',
        description: 'project_name_error_message_not_default',
        keyValue: 'project_name_error_message_not_default',
    },
    {
        name: 'Invoicing CSV',
        color: '#003366',
        description: 'invoicing_csv_exists',
        keyValue: 'invoicing_csv_exists',
    },
    {
        name: 'Invoicing XERO',
        color: '#d3d3d3',
        description: 'invoicing_xero_exists',
        keyValue: 'invoicing_xero_exists',
    },
    {
        'name': 'Invoicing via Xero DD Enabled',
        'color': '#FF5733',
        'description': 'has_enabled_invoicing_with_xero_dd',
        'keyValue': 'has_enabled_invoicing_with_xero_dd',
    },
    {
        'name': 'Invoicing via Xero Enabled',
        'color': '#33FF57',
        'description': 'has_enabled_invoicing_with_xero',
        'keyValue': 'has_enabled_invoicing_with_xero',
    },
    {
        'name': 'Xero or Xero Direct Debit Option Selected',
        'color': '#3357FF',
        'description': 'has_either_xero_or_xero_dd',
        'keyValue': 'has_either_xero_or_xero_dd',
    },
    {
        'name': 'Has Uniform User Enabled Status',
        'color': '#FF33CC',
        'description': 'has_uniform_user_enabled_status',
        'keyValue': 'has_uniform_user_enabled_status',
    },
    {
        'name': 'Has Not Mixed User Enabled Status',
        'color': '#FFC300',
        'description': 'has_not_mixed_user_enabled_status',
        'keyValue': 'has_not_mixed_user_enabled_status',
    },
    {
        'name': 'Single Sign-On (SSO) Access Configured',
        'color': '#C70039',
        'description': 'has_sso_entry',
        'keyValue': 'has_sso_entry',
    },
    {
        'name': 'Complete Schedules',
        'color': '#900C3F',
        'description': 'has_complete_schedules',
        'keyValue': 'has_complete_schedules',
    },
];

const defaultFilterValues = [
    issueLabels[0],
    issueLabels[1],
    issueLabels[2],
    issueLabels[3],
    issueLabels[6],
];

export function MissingOrgFields({ label, orgData }) {
    const localStorageSelectedLabelsKey = 'selected-org-issue-labels';
    const valuesFromLocalStorage = localStorage.getItem(localStorageSelectedLabelsKey);
    const initialFilterValues = JSON.parse(valuesFromLocalStorage) || defaultFilterValues;
    const [selectedIssues, setSelectedIssues] = useState(initialFilterValues);
    const [filteredOrganisations, setFilteredOrganisations] = useState([]);

    useEffect(() => {
        if (orgData.length) {
            const modifiedOrgs = orgData.map((org) => {
                const newOrg = {
                    organisation: org.organisation,
                };

                selectedIssues.forEach((label) => {
                    const key = label.keyValue;
                    const organisationIssues = Object.keys(org);
                    const includesSelectedIssue = organisationIssues.includes(key);

                    if (includesSelectedIssue) {
                        newOrg[key] = org[key];
                    }
                });

                return newOrg;
            });

            const filteredOrganisations = modifiedOrgs.filter((org) => Object.keys(org).length > 1);

            filteredOrganisations.sort((a, b) => Object.keys(b).length - Object.keys(a).length);
            setFilteredOrganisations(filteredOrganisations);
        }
    }, [orgData, selectedIssues]);

    const saveFilterOrgsValues = (values) => {
        setSelectedIssues(values);
        setTimeout(() => {
            const valuesString = JSON.stringify(values);
            localStorage.setItem(localStorageSelectedLabelsKey, valuesString);
        });
    };

    return !!orgData?.length && (
        <div className={styles.wrapper}>
            <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{ fontWeight: 700 }}
            >
                {label}
            </Typography>
            <div className={styles.content}>
                <MissingOrgFieldsPicker
                    initialLabels={defaultFilterValues}
                    selectedLabels={selectedIssues}
                    labels={issueLabels}
                    onChange={saveFilterOrgsValues}
                />
                {!filteredOrganisations.length
                    ? (
                        <div className={styles.emptyBlock}>
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="warning">Records not found!</Alert>
                            </Stack>
                        </div>
                    ) : (
                        <div className={styles.orgMissingFieldsContainer}>
                            {
                                filteredOrganisations.map((org, index) => (
                                    <OrgWithIssues
                                        key={`organisations-issues-${index}`}
                                        isFullWidth={selectedIssues.length > 6}
                                        orgName={org.organisation}
                                        issues={Object.keys(org)}
                                        labels={issueLabels}
                                    />
                                ))
                            }
                        </div>
                    )}
            </div>
        </div>
    );
}
