import { lazy, Suspense, useEffect } from 'react';
import './App.css';
import SignIn from './Components/Authentication/SignIn';
import ResetPassword from './Components/Authentication/ResetPassword';
import Layout from './Components/BodyComponent/Layout';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthComponent from './Components/Authentication/AuthComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import ScrollToTop from './Helpers/scroll-top';
import ServiceStatus from './Pages/ServiceStatus';
import { useSelector } from 'react-redux';
import OrgsCheck from './Pages/OrgsCheck/OrgsCheck';
import OrgInfo from './Pages/OrgInfo';
import UserInfo from './Pages/UserInfo';
import OverallStatistics from './Pages/OverallStatistics';
import FeatureFlagsPage from './Pages/FeatureFlagsPage';
import { Reports } from './Pages/Reports';
import SsoSignIn from './Components/Authentication/SsoSignIn';
import { OverallConfigurations } from './Pages/OverallConfigurations';

const SearchComponent = lazy(() => import('./Components/BodyComponent/SearchComponent'));
const Dashboard = lazy(() => import('./Components/BodyComponent/Dashboard'));
const OrgAdd = lazy(() => import('./Pages/OrgAdd'));
const FiledDocuments = lazy(() => import('./Pages/FiledDocuments'));
const NoPage = lazy(() => import('./Components/BodyComponent/NoPage'));

function ProtectedRoute({ children }) {
    const { isAuthenticated, isConsole } = useSelector((state) => state.auth);

    if (!isAuthenticated || !isConsole) {
        return <Navigate to="/login" />;
    }

    return children;
}

function AuthIsLoaded({ children }) {
    const authState = useSelector((state) => state.auth);

    if (authState.isLoading) {
        return (
            <Backdrop
                sx={{ backgroundColor: '#fff', color: '#4a78cb', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return children;
}

function App() {
    useEffect(() => {
        return () => {
            document.title = 'Avail Admin Panel';
        };
    }, []);

    return (
        <BrowserRouter>
            <AuthIsLoaded>
                <Suspense
                    fallback={(
                        <Backdrop
                            sx={{
                                backgroundColor: '#fff',
                                color: '#4a78cb',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}
                >
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                            <Route index element={<SearchComponent />} />
                            <Route path="/app/" element={<SearchComponent />} />
                            <Route path="/dashboard/" element={<Dashboard />} />
                            <Route path="/add-organisation/" element={<OrgAdd />} />
                            <Route path="/service-status/" element={<ServiceStatus />} />
                            <Route path="/filed-documents/" element={<FiledDocuments />} />
                            <Route path="/check-organisations/" element={<OrgsCheck />} />
                            <Route path="/overall-configurations/" element={<OverallConfigurations />} />
                            <Route path="/overall-statistics/" element={<OverallStatistics />} />
                            <Route path="/reports/" element={<Reports />} />
                            <Route
                                path="/search/organisations/:orgName/"
                                element={<OrgInfo />}
                            />
                            <Route
                                path="/search/users/:userEmail/ids/:userId/"
                                element={<UserInfo />}
                            />
                            <Route
                                path="/feature-flags/"
                                element={<FeatureFlagsPage />}
                            />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                        <Route
                            path="/login"
                            element={<AuthComponent childComponent={<SignIn />} />}
                        />
                        <Route
                            path="/reset-password"
                            element={<AuthComponent childComponent={<ResetPassword />} />}
                        />
                        <Route
                            path="/sso"
                            element={<AuthComponent childComponent={<SsoSignIn />} />}
                        />
                    </Routes>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{ width: '350px' }}
                    />
                </Suspense>
            </AuthIsLoaded>
        </BrowserRouter>
    );
}

export default App;
