import { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import styles from './MissingInvoicesTab.module.css';
import { downloadInvoicingReportsZip } from '../../../../Services/Search.services';

export function MissingInvoicesTab() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleDownloadClick = () => {
        setIsLoading(true);

        downloadInvoicingReportsZip()
            .then((isSuccess) => {
                setIsSuccess(!!isSuccess);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={styles.buttonWrapper}>
            <Button
                variant="contained"
                sx={isSuccess && {
                    bgcolor: green[500],
                    '&:hover': {
                        bgcolor: green[700],
                    },
                }}
                disabled={isLoading}
                onClick={handleDownloadClick}
            >
                Download Missing Invoices
            </Button>
            {isLoading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </div>
    );
}
