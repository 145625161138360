import { useState } from 'react';
import { Box, Card, CardContent, Grid, Paper, Tab, Tabs } from '@mui/material';
import { MissingInvoicesTab } from '../Components/BodyComponent/Reports/Tabs/MissingInvoicesTab';
import { Header } from '../Components/Shared/Header/Header';

const PageTabs = {
    missingInvoices: 0,
};

export function Reports() {
    const [tabId, setTabId] = useState(0);

    const handleTabChange = (event, newTabId) => {
        setTabId(newTabId);
    };

    let currentTabComponent;
    switch (tabId) {
        case PageTabs.missingInvoices:
            currentTabComponent = <MissingInvoicesTab />;
            break;
    }

    return (
        <>
            <div className="header">
                <Header title="Reports" />
            </div>
            <Grid item xs={12} sm={12} md={12} sx={{ mt: 2, mb: 2 }}>
                <Card component={Paper}>
                    <CardContent>
                        <Box sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Tabs
                                    onChange={handleTabChange}
                                    value={tabId}
                                >
                                    <Tab label="Missing Invoices" />
                                </Tabs>
                            </Box>
                        </Box>
                        {currentTabComponent}
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}
