import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import PropTypes from 'prop-types';
import styles from './SearchBarWrapper.module.css';

function SearchBarWrapper({ component, query }) {
    const navigate = useNavigate();
    const parentGetResultObj = (searchRDt) => {
        const type = searchRDt.type;
        if (type === 'user') {
            navigate(`/search/users/${searchRDt.data}/ids/${searchRDt.id}/`);
        } else if (type === 'company') {
            navigate(`/search/organisations/${searchRDt.data}/`);
        }
    };

    return (
        <>
            <div className={styles.searchWrapper}>
                <SearchBar
                    query={query}
                    childGetResultObj={parentGetResultObj}
                    placeholder="Enter the search keyword..."
                    data={[]}
                />
            </div>
            {component}
        </>
    );
}

SearchBarWrapper.propTypes = {
    component: PropTypes.object,
};
export default SearchBarWrapper;
