import React from 'react';
import { useParams } from 'react-router-dom';
import UserDetails from '../Components/BodyComponent/Users/UserDetails';
import SearchBarWrapper from '../Components/BodyComponent/SearchBar/SearchBarWrapper';

function UserInfo() {
    const { userEmail: userEmail, userId: userId } = useParams();
    return (
        <SearchBarWrapper component={<UserDetails userId={userId} email={userEmail} />} />
    );
}
UserInfo.propTypes = {};
export default UserInfo;